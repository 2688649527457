<template>
    <div class="card">
        <div class="server-item">
            <el-col :sm="24">
                <div class="name" :class="item.online?'':'offline'" style="font-size: 1.5rem;">
                    {{ item.gg18_ }}
                    <span class="float-right text-primary">
                        {{ item.sub_server }}
                    </span>
                </div>
            </el-col>
            <el-col :sm="12">
                <div class="title">IP：</div>
                <div class="value">
                    {{ item.ip }}:{{ item.gg57_ }}
                </div>
            </el-col>
            <el-col :sm="12">
                <div class="title">更新时间：</div>
                <div class="value">
                    <format-time :time="item.time"></format-time>
                </div>
            </el-col>
            <el-col :sm="12">
                <div class="title">父级IP：</div>
                <div class="value">
                    {{ item.gg54_ }}
                </div>
            </el-col>
            <el-col :sm="12">
                <div class="title">上传量：</div>
                <div class="value">
                    <format-b :data="item.gg12_"></format-b>
                </div>
            </el-col>
            <el-col :sm="12">
                <div class="title">POST1/POST2</div>
                <div class="value">
                    {{ item.gg13_ }}
                    /
                    {{ item.gg14_ }}
                </div>
            </el-col>
            <el-col :sm="12">
                <div class="title">请求数</div>
                <div class="value">
                    {{ item.gg9_ }}
                    /
                    <!--                    {{ item.waiting_length }}-->
                    <!--                    /-->
                    {{ item.gg9_ > item.gg22_ ? item.gg22_ : item.gg9_ }}
                    /
                    {{ item.gg10_ }}
                </div>
            </el-col>
            <el-col :sm="12">
                <div class="title">出错 POST2/瓦片</div>
                <div class="value">
                    {{ item.gg28_ }}/{{ item.gg45_ }}
                </div>
            </el-col>
            <el-col :sm="12">
                <div class="title">客户端版本：</div>
                <div class="value">
                    {{ item.gg25_ }}
                </div>
            </el-col>
            <el-col :sm="12">
                <div class="title">管理端版本：</div>
                <div class="value">
                    {{ item.manager_version }}
                </div>
            </el-col>
            <el-col :sm="12">
                <div class="title">购买账号：</div>
                <div class="value">
                    {{ item.gg34_ }}
                </div>
            </el-col>
            <el-col :sm="12">
                <div class="title">过期时间：</div>
                <div class="value"
                     :class="{error:item.gg33_-new Date().getTime()/1000<86400*2}">
                    <format-time format="Y-m-d" :time="item.gg33_"></format-time>
                </div>
            </el-col>
            <el-col :sm="12">
                <div class="title">每秒允许请求数据：</div>
                <div class="value">
                    {{ item.gg22_ }}
                </div>
            </el-col>
            <el-col :sm="12">
                <div class="title">上次暂停时间：</div>
                <div class="value">
                    <format-time format="Y-m-d H:i:s" :time="item.gg36_"></format-time>
                </div>
            </el-col>
            <el-col :sm="12">
                <div class="title">区域：</div>
                <div class="value">
                    {{ item.gg38_ }}
                </div>
            </el-col>
            <el-col :sm="12">
                <div class="title">代理IP：</div>
                <div class="value">
                    {{ item.gg42_ }}
                </div>
            </el-col>
            <el-col :sm="12">
                <div class="title">POST1放行比例：</div>
                <div class="value">
                    {{ item.gg44_ }}
                </div>
            </el-col>
            <el-col :xs="24">
                <div>
                                <span class="switch-item">
                                <el-switch
                                    @change="changeStatus(item.ip,{key:'gg26_',item})"
                                    active-text="历史数据"
                                    inactive-color="#FF4343"
                                    :value="item.gg26_"
                                >
                                </el-switch>
                                </span>
                    <span class="switch-item">
                                <el-switch
                                    @change="changeStatus(item.ip,{key:'gg21_',item})"
                                    active-text="固定POST2"
                                    :value="item.gg21_"
                                >
                                </el-switch>
                                </span>
                    <!--                    <span class="switch-item">-->
                    <!--                                <el-switch-->
                    <!--                                    @change="changeStatus(item.ip,{key:'gg52_',item})"-->
                    <!--                                    active-text="启用缓冲"-->
                    <!--                                    :value="item.gg52_"-->
                    <!--                                >-->
                    <!--                                </el-switch>-->
                    <!--                                </span>-->
                    <span class="switch-item">
                                <el-switch
                                    @change="changeStatus(item.ip,{key:'gg43_',item})"
                                    active-text="手机访问"
                                    :value="item.gg43_"
                                >
                                </el-switch>
                                </span>
                    <span class="switch-item">
                                <el-switch
                                    disabled="disabled"
                                    active-text="代理状态"
                                    :value="item.gg31_"
                                >
                                </el-switch>
                                </span>
                </div>
            </el-col>
            <el-col :md="24">
                <el-link class="mr-2">
                    <a class="circle" :class="item.online?'':'offline'" href="javascript:void(0);">

                    </a>
                </el-link>
                <el-link @click="run(item.ip,{action:'config',item})" class="mr-2" type="primary">
                    设置
                </el-link>
                <el-link @click="run(item.ip,{action:'create-server',item})" class="mr-2" type="primary">
                    创建服务器
                </el-link>
                <el-link @click="run(item.ip,{action:'start-server',item})" class="mr-2" type="primary">
                    重启服务
                </el-link>
                <el-link @click="run(item.ip,{action:'update-server',item})" class="mr-2" type="primary">
                    更新程序
                </el-link>
                <!--                <el-link @click="run(item.ip,{action:'update-manager',item})" class="mr-2" type="primary">-->
                <!--                    更新主程序-->
                <!--                </el-link>-->
                <!--                <el-link @click="run(item.ip,{action:'proxy-subscribe',item})" class="mr-2" type="primary">-->
                <!--                    代理订阅-->
                <!--                </el-link>-->
                <!--                <el-link @click="run(item.ip,{action:'proxy-config',item})" class="mr-2" type="primary">-->
                <!--                    直接配置代理-->
                <!--                </el-link>-->
                <el-link @click="run(item.ip,{action:'get-proxy-server',item})" class="mr-2" type="primary">
                    选择代理
                </el-link>
                <el-link @click="run(item.ip,{action:'log-server',item})" class="mr-2" type="primary">查看日志
                </el-link>
                <el-link @click="run(item.ip,{action:'second-download',item,day:'session'})" class="mr-2"
                         type="primary">
                    查看session
                </el-link>
                <el-link @click="run(item.ip,{action:'second-download',item,day:'day'})" class="mr-2"
                         type="primary">上传量
                </el-link>
                <el-link v-for="i in 4" :key="i" class="mr-3"
                         @click="run(item.ip,{action:'second-download',day:'1-'+(i-1)})" type="primary">
                    1-{{ i - 1 }}
                </el-link>
                <el-link v-for="i in 4" :key="i+'2'" class="mr-3"
                         @click="run(item.ip,{action:'second-download',day:'2-'+(i-1)})" type="primary">
                    2-{{ i - 1 }}
                </el-link>
                <el-link @click="run(item.ip,{action:'stop-server',item})" class="mr-2" type="info">停止服务
                </el-link>
                <el-link @click="run(item.ip,{action:'test-server',item})" class="mr-2" type="info">
                    测试获取session
                </el-link>
                <el-link @click="run(item.ip,{action:'delete-server',item})" class="mr-2" type="info">
                    删除此服务器
                </el-link>
            </el-col>
        </div>
        <el-dialog
            :title="day_statistics.title+'每日上传量'"
            :visible.sync="day_statistics.show"
            width="85%"
            @opened="opened"
        >
            <div>
                <div style="height: 400px;" :id="day_statistics.id">

                </div>
            </div>
            <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="day_statistics.show = false">确 定</el-button>
  </span>
        </el-dialog>
        <server-subscribe :subscribe="subscribe.list" :show="subscribe.show"
                          :title="subscribe.title"></server-subscribe>
        <server-log ref="log-div" :log="log.log" :show="log.show" :title="log.title"></server-log>
        <form-dialog @closeFormDialog="submit" :title="form.title" :show="form.show" :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
    </div>

</template>

<script>
import FormatTime from '@/components/common/format-time';
import FormatB from '@/components/common/format-b';
import ServerLog from './server-log';
import ServerSubscribe from './server-subscribe';
import store from '@/vuex/index';
import F from "@/fields/fields";
import Echarts from "echarts";

export default {
    name: "server-item",
    components: {
        FormatTime, FormatB, ServerLog, ServerSubscribe
    },
    data: function () {
        return {
            day_statistics: {
                show: false,
                data: [],
                title: '',
                id: 'xxx'
            },
            baseUrl: '/google/api/server?bigemap=1',
            log: {
                log: [],
                show: false,
                title: '',
            },
            subscribe: {
                list: [],
                show: false,
                title: '',
            },
            form: {
                actionName: '',
                show: false,
                title: '',
                data: {},
                fields: [],
                rules: {},
                index: null,
            }
        }
    },
    props: {
        item: {
            require: true,
            type: Object
        }
    },
    methods: {
        opened: function () {
            let op = {
                title: [],
                value: []
            };
            this.day_statistics.data.map(v => {
                v = JSON.parse(v);
                if (v.gg12_ && v.gg12_.data) {
                    op.title.push(v.time);
                    v.gg12_.number = v.gg12_.number / 1024 / 1024;
                    isNaN(v.gg12_.number) ? v.gg12_.number = 0.0 : true;
                    op.value.push(v.gg12_.number.toFixed(1));
                }
            });
            let myChart = Echarts.init(document.getElementById(this.day_statistics.id));
            // 指定图表的配置项和数据
            let option = {
                title: {
                    text: '每日上传量',
                    show: false,
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                grid: {
                    left: '3%',
                    right: '3%',
                    bottom: '0',
                    containLabel: true
                },
                legend: {
                    show: false,
                    data: ['上传量']
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: op.title
                },
                yAxis: {
                    type: 'value',
                },
                series: [
                    {
                        name: '上传量',
                        type: 'line',
                        // stack: '总量',
                        smooth: true,
                        lineStyle: {
                            width: 2
                        },
                        showSymbol: false,
                        areaStyle: {
                            opacity: 0.8,
                            color: new Echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: 'rgba(128, 255, 165)'
                            }, {
                                offset: 1,
                                color: 'rgba(1, 191, 236)'
                            }])
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: op.value
                    },
                    //     {
                    //     name: '上传量',
                    //     type: 'line',
                    //     smooth: true,
                    //     data: op.value
                    // }
                ]
            };
            myChart.setOption(option);
        },
        run: function (ip, type) {
            let serverName, form;
            ip = ip.split('.').slice(0, 4).join('.');
            if (store.state.loading) {
                return;
            }
            let gg57_ = type.item.gg57_ || 9502;
            switch (type.action) {
                case 'config':
                    type.item = JSON.parse(JSON.stringify(type.item));
                    if (!type.item.gg18_ && type.item.gg57_ != '9502') {
                        //没有ID，同时是创建出来的，那么会自动填充相应的信息
                        type.item.gg18_ = 'history';
                        type.item.gg34_ = '创建';
                        type.item.gg33_ = Math.floor(new Date().getTime() / 1000) + 86400 * 365;
                        type.item.gg54_ = ip + ':9502';
                        type.item.gg38_ = '香港';
                    }
                    form = F.get('historyServer', 'editConfig', Object.assign({}, type.item, {ip}));
                    form.actionName = 'editConfig';
                    type.item.gg18_ ? form.title = type.item.gg18_ + '  ' + form.title : true;
                    form.index = type.index;
                    this.form = form;
                    break;
                case 'create-server':
                    form = F.get('historyServer', 'createServer', Object.assign({}, {ip}));
                    form.actionName = 'createServer';
                    this.form = form;
                    break;
                case 'start-server':
                    serverName = type.item.gg18_ || '此服务器';
                    this.$confirm(`你确认要重启 ${serverName} 上面的服务程序吗?`, '操作确认').then(() => {
                        this.$axiosServer.post(this.baseUrl, {action: type.action, gg57_, ip: ip}).then(() => {
                            this.$message.success('重启成功!');
                        });
                    });
                    break;
                case 'update-server':
                    serverName = type.item.gg18_ || '此服务器';
                    this.$confirm(`你确认要更新 ${serverName} 上面的服务程序吗?`, '操作确认').then(() => {
                        this.$axiosServer.post(this.baseUrl, {action: type.action, gg57_, ip: ip}).then(() => {
                            this.$message.success('更新成功!');
                        });
                    });
                    break;
                case 'update-manager':
                    serverName = type.item.gg18_ || '此服务器';
                    this.$confirm(`你确认要更新 ${serverName} 上面的管理程序吗?`, '操作确认').then(() => {
                        this.$axiosServer.post(this.baseUrl, {action: type.action, gg57_, ip: ip}).then(() => {
                            this.$message.success('更新成功!');
                        });
                    });
                    break;
                case 'stop-server':
                    serverName = type.item.gg18_ || '此服务器';
                    this.$confirm(`你确认要停止 ${serverName} 上面的服务程序吗?`, '操作确认').then(() => {
                        this.$axiosServer.post(this.baseUrl, {action: type.action, gg57_, ip: ip}).then(() => {
                            this.$message.success('停止服务成功!');
                        });
                    });
                    break;
                case 'test-server':
                    serverName = type.item.gg18_ || '此服务器';
                    this.$confirm(`你确认要测试当前服务器 ${serverName} 是否被封吗?注意:请不要随便测试。否则会有问题！！`, '操作确认').then(() => {
                        this.$axiosServer.post(this.baseUrl, {action: type.action, gg57_, ip: ip}).then(() => {
                            this.$message.success('测试成功!');
                        });
                    });
                    break;
                case 'delete-server':
                    serverName = type.item.gg18_ || '此服务器';
                    this.$confirm(`你确认要删除 ${serverName} 此服务器吗?`, '操作确认').then(() => {
                        this.$axiosServer.post(this.baseUrl, {action: type.action, gg57_, ip: ip}).then(() => {
                            this.$emit('update', {type: 'delete', ip});
                            this.$message.success('删除成功!');
                        });
                    });
                    break;
                case 'log-server':
                    this.log.show = false;
                    this.log.title = type.item.gg18_ + '  日志记录';
                    this.$axiosServer.post(this.baseUrl, {action: type.action, gg57_, ip: ip}).then((data) => {
                        if (!data.log) return;
                        this.log.show = true;
                        this.log.log = data.log.map(v => atob(v));
                        // if (logDiv) {
                           setTimeout(()=>{
                               let logDiv=this.$refs['log-div'];
                               let logDom=logDiv.$el.getElementsByClassName('log-area')[0];
                               logDom.scrollTop = logDom.scrollHeight;
                           },500);
                        // }
                    });
                    break;
                case 'get-proxy-server':
                    this.$axiosServer.post(this.baseUrl, {action: type.action, gg57_, ip: ip}).then((proxy) => {
                        // console.log(type.item);
                        form = F.get('historyServer', 'selectProxy', Object.assign({}, type.item));
                        type.item.gg18_ ? form.title = type.item.gg18_ + '  ' + form.title : true;
                        let options = [];
                        Object.values(proxy.data.subscribe).map(v => {
                            let tmp = {value: v.id + '', label: v.title, children: []};
                            let children = v.children || v.list;
                            for (let k in children) {
                                let label;
                                try {
                                    label = JSON.parse(children[k].info);
                                    switch (children[k]['status']) {
                                        case 2:
                                        case 100:
                                            label.ps = label.ps + '--' + children[k]['status'];
                                            break;
                                    }
                                } catch (e) {
                                    label = {ps: 'error'};
                                }
                                //有些情况下订阅是没有对应的服务器信息，公公是机场提示使用的
                                if (!label) continue;
                                tmp.children.push({
                                    label: label.ps,
                                    value: children[k].key,
                                });
                            }
                            options.push(tmp);
                        });
                        // console.log(options);
                        form.fields = form.fields.map(v => {
                            if (v.name === 'gg41_') {
                                v.options = options;
                            }
                            return v;
                        });
                        // console.log(form);
                        form.actionName = 'selectProxy';
                        form.index = type.index;
                        this.form = form;
                    });
                    break;
                case 'proxy-subscribe':
                    this.subscribe.show = false;
                    this.subscribe.title = type.item.gg18_ ? type.item.gg18_ + '  订阅设置' : '订阅设置';
                    // http://127.0.0.1:9502/google/api/subscribe?bigemap=1
                    this.$axiosServer.get('/google/api/subscribe?bigemap=1&all=1').then((data) => {
                        // console.log(data);
                        this.subscribe.list = Object.values(data.data.subscribe);
                        this.subscribe.show = true;
                    });
                    break;
                case 'proxy-config':
                    form = F.get('historyServer', 'editProxy', Object.assign({}, type.item, {ip, gg57_}));
                    type.item.gg18_ ? form.title = type.item.gg18_ + '  ' + form.title : true;
                    form.actionName = 'editProxy';
                    form.index = type.index;
                    this.form = form;
                    break;
                case 'second-download':
                    this.$axiosServer.post(this.baseUrl, {
                        action: type.action,
                        gg57_,
                        ip: ip,
                        day: type.day
                    }).then((data) => {
                        if (!data.data || !data.data.log) return;
                        data.data.log = data.data.log.map(v => {
                            return typeof v === 'object' ? JSON.stringify(v) : v;
                        });
                        switch (type.day) {
                            case 'day':
                                if (!data.data.log.length) return;
                                this.day_statistics.show = true;
                                this.day_statistics.data = data.data.log;
                                this.day_statistics.id = 'ddd' + new Date().getTime();
                                this.day_statistics.title = type.item.gg18_;
                                break;
                            default:
                                if (data.data.log.length > 1) {
                                    let unique = Array.from(new Set(data.data.log));
                                    this.$message.success('共有' + (unique.length - 1) + '条数据');
                                }
                                var blob = new Blob([data.data.log]);
                                var href = URL.createObjectURL(blob);
                                var a = document.createElement('a');
                                a.setAttribute('href', href);
                                a.setAttribute('download', 'post-' + type.day + '.json');
                                a.click();
                        }
                    });
                    break;
            }
        },
        changeStatus: function (ip, data) {
            let postData = {ip, action: 'config'};
            let title = '';
            switch (data.key) {
                case 'gg21_':
                    if (data.item.gg21_) {
                        postData.gg21_ = '';
                        title = '你确认要禁用固定POST2模式吗?';
                    } else {
                        postData.gg21_ = 1;
                        title = '你确认要启用固定POST2模式吗?';
                    }
                    break;
                case 'gg26_':
                    if (data.item.gg26_) {
                        postData.gg26_ = '';
                        title = '你确认要禁用谷歌历史服务吗?';
                    } else {
                        postData.gg26_ = 1;
                        title = '你确认要启用谷歌历史服务吗?';
                    }
                    break;
                case 'gg43_':
                    if (data.item.gg43_) {
                        postData.gg43_ = '';
                        title = '你确认要禁用手机端的谷歌历史服务吗?';
                    } else {
                        postData.gg43_ = 1;
                        title = '你确认要启用手机端的谷歌历史服务吗?';
                    }
                    break;
                case 'gg52_':
                    if (data.item.gg52_) {
                        postData.gg52_ = '';
                        title = '你确认要禁用缓冲区功能吗?';
                    } else {
                        postData.gg52_ = 1;
                        title = '你确认要启用缓冲区功能吗?';
                    }
                    break;
                default:
                    return;
            }
            postData.gg57_ = data.item.gg57_;
            postData.gg18_ = data.item.gg18_;
            this.$confirm(title, '操作确认').then(() => {
                this.$axiosServer.post(this.baseUrl, postData).then(() => {
                    this.$message.success('操作成功!');
                    // this.$emit('update', {ip, data: serverData.data});
                });
            });
        },
        submit: function (data) {
            if (data.type === 'cancel') {
                this.form.show = false;
                return;
            }
            switch (this.form.actionName) {
                case 'editConfig':
                    data.data.action = 'config';
                    if (data.data.gg18_ == 'history') {
                        this.$message.warning('服务器ID不正确!');
                        return;
                    }
                    this.$axiosServer.post(this.baseUrl, data.data).then((serverData) => {
                        this.$message.success('保存成功!');
                        serverData.data && this.$emit('update', {ip: data.data.ip, data: serverData.data});
                    });
                    break;
                case 'editAll':
                    data.data.action = 'config';
                    this.setAll.data = {
                        action: 'config',
                        [this.setAll.type]: data.data.__all__,
                    }
                    this.runAll();
                    break;
                case 'editSubscribe':
                    data.data.action = 'edit-subscribe';
                    this.$axiosServer.post(this.baseUrl, data.data).then(() => {
                        this.$message.success('保存成功!');
                    });
                    break;
                case 'editProxy':
                    data.data.action = 'edit-proxy';
                    if (!data.data.gg31_) data.data.gg31_ = '';
                    this.$axiosServer.post(this.baseUrl, data.data).then((serverData) => {
                        this.$message.success('保存成功!');
                        serverData.data && this.$emit('update', {ip: data.data.ip, data: serverData.data});
                    });
                    break;
                case 'selectProxy':
                    data.data.action = 'select-proxy';
                    if (!data.data.gg31_) data.data.gg31_ = '';
                    // console.log(data.data);
                    this.$axiosServer.post(this.baseUrl, data.data).then((serverData) => {
                        this.$message.success('保存成功!');
                        serverData.data && this.$emit('update', {ip: data.data.ip, data: serverData.data});
                    });
                    break;
                case 'createServer':
                    data.data.action = 'create-server';
                    if (data.data.gg55_ === data.data.gg57_) {
                        this.$message.warning('不可能使用相同的端口号');
                        return;
                    }
                    this.$axiosServer.post(this.baseUrl, data.data).then(() => {
                        this.$message.success('保存成功!');
                    });
                    break;
            }
            this.form.show = false;
        }
    }
}
</script>

<style scoped lang="less">
.server-item {
    color: #222;
    //height: 360px;
    .name {
        height: 50px;
        //display: inline-block;
        &.offline {
            color: red;
        }
    }

    .title {
        color: #666;
        font-size: 0.85rem;
        height: 25px;
    }

    .circle {
        display: inline-block;
        width: 8px;
        background: #369;
        height: 8px;
        border-radius: 100%;

        &.offline {
            background: red;
        }
    }

    .switch-item {
        display: inline-block;
        padding: 0.4rem;
        margin-right: 1rem;
    }

    .value {
        min-height: 28px;

        &.error {
            color: red;
        }
    }
}
</style>
