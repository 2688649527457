<template>
    <div>
        <el-row :gutter="10">
            <el-col :sm="12" :md="6">
                <div class="card bg_user_add">
                    <div class="text-right">
                        <div class="float-left card-icon">
                            <icon-font icon-class="configTitle[item]['icon']"></icon-font>
                        </div>
                        <div class="">
                            <p class="sub-title">今日上传总量</p>
                            <format-b style="font-size: 1.5rem;" :data="serverInfo.gg12_"></format-b>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :sm="12" :md="6">
                <div class="card bg_user_add">
                    <div class="text-right">
                        <div class="float-left card-icon">
                            <icon-font icon-class="configTitle[item]['icon']"></icon-font>
                        </div>
                        <div class="">
                            <p class="sub-title">暂停/在线/总数</p>
<!--                            <countTo class="count" :startVal="0" :endVal="serverInfo.deadline"-->
<!--                                     :duration='500'></countTo>-->
<!--                            /-->
                            <countTo class="count" :startVal="0" :endVal="serverInfo.disabled"
                                     :duration='500'></countTo>
                            /
<!--                            <countTo class="count" :startVal="0" :endVal="serverInfo.proxy"-->
<!--                                     :duration='500'></countTo>-->
<!--                            /-->
                            <countTo class="count" :startVal="0" :endVal="serverInfo.online"
                                     :duration='500'></countTo>
                            /
                            <countTo class="count" :startVal="0" :endVal="serverInfo.sum"
                                     :duration='500'></countTo>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :sm="12" :md="6">
                <div class="card bg_user_add">
                    <div class="text-right">
                        <div class="float-left card-icon">
                            <icon-font icon-class="configTitle[item]['icon']"></icon-font>
                        </div>
                        <div class="">
                            <p class="sub-title">总请求/允许请求/完成请求</p>
                            <span style="font-size: 1.5rem;">
                                    {{ serverInfo.gg9_ }}
                               </span>
                            /
                            <span style="font-size: 1.5rem;">
                                    {{ serverInfo.allow }}
                               </span>
                            /
                            <span style="font-size: 1.5rem;">
                                    {{ serverInfo.gg10_ }}
                               </span>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :sm="12" :md="6">
                <div class="card bg_user_add">
                    <div class="text-right">
                        <div class="float-left card-icon">
                            <icon-font icon-class="configTitle[item]['icon']"></icon-font>
                        </div>
                        <div class="">
                            <p class="sub-title">POST1 / POST2</p>
                            <countTo class="count" :startVal="0" :endVal="serverInfo.gg13_"
                                     :duration='500'></countTo>
                            /
                            <countTo class="count" :startVal="0" :endVal="serverInfo.gg14_"
                                     :duration='500'></countTo>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-row :gutter="15">
            <el-col :sm="24">
                <div class="mt-3">
                    <el-button @click="allConfig('gg22_')" type="primary">统一设置每秒请求数</el-button>
                    <el-button @click="allConfig('gg44_')" type="primary">统一设置POST1放行比例</el-button>
                    <el-button @click="allConfig('gg43_')" type="primary">统一设置手机访问</el-button>
                </div>
            </el-col>
        </el-row>
        <el-row :gutter="15">
            <div class="mt-3">
                <el-col :sm="4">
                    <el-input v-model="filter.id" placeholder="服务器ID"></el-input>
                </el-col>
                <el-col :sm="4">
                    <el-input v-model="filter.ip" placeholder="服务器IP"></el-input>
                </el-col>
                <el-col :sm="4">
                    <el-input v-model="filter.buyer" placeholder="购买者"></el-input>
                </el-col>
                <el-col :sm="8">
                    <div style="margin-top:10px;">
                        <el-checkbox-group v-model="filter.type">
                            <el-checkbox label="inner" >内网服务器</el-checkbox>
                            <el-checkbox label="outer" >外网服务器</el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-col>
            </div>
        </el-row>
        <el-row :gutter="15">
            <el-col :sm="24">
                <div class="mt-3">
                    <el-button size="mini" v-for="item in order.list"
                               :icon="order.sort==='asc'?'el-icon-top':'el-icon-bottom'" :key="item.value"
                               @click="sort(item.value)" :type="order.order===item.value?'primary':'default'">
                        {{ item.title }}
                    </el-button>
                </div>
            </el-col>
        </el-row>
        <el-row :gutter="15">
            <el-col v-for="(item,key) in serverInfo.list" :key="key" :md="8" :xs="24" :sm="12">
                <server-item @update="serverUpdate" :item="item" :key="key"></server-item>
            </el-col>
        </el-row>
        <form-dialog @closeFormDialog="submit" :title="form.title" :show="form.show" :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
    </div>
</template>

<script>

import 'echarts/lib/component/title';
import 'echarts/lib/component/grid';
import countTo from 'vue-count-to';
import ServerItem from './server-item';
import FormatB from '@/components/common/format-b';
import F from "@/fields/fields";
import ServerWebsocket from "./server-websocket";

// let websocket;
export default {
    name: "index",
    components: {
        countTo, FormatB, ServerItem
    },
    created() {
        this.websocket = new ServerWebsocket('ws://google.zhangwei.fun:9502/client', {
            message: (message) => {
                let data;
                if (!this.websocket) return;
                this.websocket.socket.send('');
                try {
                    data = JSON.parse(message.data);
                } catch (e) {
                    return;
                }
                this.serverItem = data.data;
            }, open: () => {
                if (!this.websocket) return;
                this.websocket.socket.send(JSON.stringify({action: 'connect'}));
            }
        })
    },
    beforeDestroy() {
        this.websocket.close();
    },
    data: function () {
        return {
            websocket: null,
            setAll: {
                index: 0,
                running: false,
                list: [],
                type: '',
                data: {},
            },
            filter:{
                type:['inner','outer'],
                ip:'',
                id:'',
                buyer:'',
            },
            baseUrl: '/google/api/server?bigemap=1',
            serverItem: {},
            value1: true,
            order: {
                list: [{title: '名称', value: 'gg18_'}, {title: '每秒请求数', value: 'gg9_'}, {
                    title: '上传量',
                    value: 'gg12_'
                }, {title: '启用状态', value: 'enable'},{title: '在线情况', value: 'time'}, {title: 'IP', value: 'ip'}, {
                    title: 'POST1',
                    value: 'gg13_'
                }, {title: 'POST2', value: 'gg14_'}],
                order: 'gg18_',
                sort: 'asc'
            },
            form: {
                actionName: '',
                show: false,
                title: '',
                data: {},
                fields: [],
                rules: {},
                index: null,
            },
            aaaa: []
        }
    },
    mounted() {

        setTimeout(() => {
            // this.aaaa = this.serverInfo.list;
            this.aaaa = this.serverInfo.list.filter(value => {
                let serverId=value.gg18_.replace('history','');
                serverId=parseInt(serverId);
                // return value.gg25_ != '2.0.1-proxy'&&serverId<1000;
                // return serverId>1000;
            });
            // console.log(this.aaaa);
            // setInterval(() => {
            //     let current = this.aaaa.pop();
            //     if (!current) {
            //         console.log('nonono');
            //         return;
            //     }
            //     console.log(this.aaaa.length);
            //     // console.log(current);
            //     let pp = {action: "update-server", gg57_: current['gg57_'], ip: current.ip};
            //     // console.log(pp);
            //     this.$axiosServer.post('http://google.zhangwei.fun:9502/google/api/server?bigemap=1', pp).then(() => {
            //
            //     });
            // }, 4000);
            // setInterval(() => {
            //     let current = this.aaaa.pop();
            //     if (!current) {
            //         console.log('nonono');
            //         return;
            //     }
            //     console.log(this.aaaa.length);
            //     // console.log(current);
            //     let pp = {action: "config", gg57_: current['gg57_'],gg22_:45, ip: current.ip};
            //     // console.log(pp);
            //     this.$axiosServer.post('http://google.zhangwei.fun:9502/google/api/server?bigemap=1', pp).then(() => {
            //
            //     });
            // }, 1000);

            // this.serverInfo.list.map(v=>{
            //     // let data={"ip":"149.129.52.242","gg22_":"50","action":"config"};
            //     // console.log(v);
            //     // console.log(data);
            //     // data.ip=v.ip;
            //     // let data={
            //     //     ip:v.ip,
            //     //     action:'a',
            //     // }
            //     // this.$axiosServer.post(this.baseUrl, data).then(() => {
            //     //
            //     // });
            // });
        }, 3000);
    },
    computed: {
        serverInfo: function () {
            let base = {
                list: [],
                total: 0,
                post1: 0,
                post2: 0,
                deadline: 0,
                waiting: 0,
                online: 0,
                disabled: 0,
                proxy: 0,
                running: 0,
                sum: 0,
                allow: 0,
                gg9_: 0,
                gg10_: 0,
                gg12_: 0,
                gg13_: 0,
                gg14_: 0
            };
            let arr = [];
            let parseT = JSON.parse(JSON.stringify(this.serverItem));
            for (let k in parseT) {
                let tmp = parseT[k];
                if (!tmp['gg18_']) tmp['gg18_']='';
                let serverId=tmp['gg18_'].toString().replace('history','');
                serverId=parseInt(serverId);
                if (!isNaN(serverId)){
                    serverId=serverId>=1000?'inner':"outer";
                    if (this.filter.type.indexOf(serverId)===-1) continue;
                    // if (serverId>=1000&&this.filter.type.indexOf('outer')===-1) continue;
                    // if (serverId<1000&&this.filter.type.indexOf('inner')===-1) continue;
                }
                if (this.filter.id&&tmp['gg18_']&&tmp['gg18_'].toString().indexOf(this.filter.id)===-1) continue;
                if (this.filter.buyer&&tmp['gg34_']&&tmp['gg34_'].toString().indexOf(this.filter.buyer)===-1) continue;
                if (this.filter.ip&&k.toString().indexOf(this.filter.ip)===-1) continue;
                // tmp.ip = k;
                base.sum++;
                tmp['waiting_length'] = isNaN(parseInt(tmp['waiting_length'])) ? 0 : parseInt(tmp['waiting_length']);
                base.waiting += tmp['waiting_length'];
                if (!isNaN(parseInt(tmp['time']))) {
                    if (parseInt(tmp['time']) * 1000 + 60 * 1000 > new Date().getTime()) {
                        tmp.online = true;
                        base.online++;
                    } else {
                        tmp.online = false;
                    }
                    tmp.time = parseInt(tmp['time']);
                } else {
                    tmp.time = 0;
                    tmp.online = false;
                }
                if (tmp.gg31_) base.proxy++;
                if (tmp.gg33_ && !isNaN(parseFloat(tmp.gg33_))) {
                    tmp.gg33_ - new Date().getTime() / 1000 < 86400 * 2 ? base.deadline++ : true;
                }
                tmp['gg22_'] = parseInt(tmp['gg22_']);
                tmp['gg9_'] = tmp['gg47_'];
                tmp['gg10_'] = tmp['gg48_'];
                ['gg9_', 'gg10_', 'gg12_', 'gg13_', 'gg14_', 'gg28_','gg45_'].map(v => {
                    if (tmp[v] && tmp[v]['number']) {
                        try {
                            tmp[v] = parseFloat(tmp[v]['number']);
                            if (!base[v]) base[v] = 0;
                            base[v] += tmp[v];
                            if (v === 'gg9_') {
                                if (tmp[v] > tmp['gg22_']) {
                                    base['allow'] += tmp['gg22_'];
                                } else {
                                    base['allow'] += tmp[v];
                                }
                            }
                        } catch (e) {
                            tmp[v] = 0;
                        }
                    } else {
                        tmp[v] = 0
                    }
                });
                ['gg26_', 'gg21_', 'gg31_', 'gg43_', 'gg52_'].map(v => {
                    tmp[v] = !!tmp[v] || false;
                });
                if (!tmp['gg26_']) base.disabled++;
                tmp.gg33_ = isNaN(parseInt(tmp.gg33_)) ? 0 : parseInt(tmp.gg33_);
                tmp.gg36_ = isNaN(parseInt(tmp.gg36_)) ? 0 : parseInt(tmp.gg36_);
                arr.push(tmp);
            }
            //最后对arr进行排序
            arr.sort((a, b) => {
                let t1;
                let t2;
                let k = this.order.order;
                switch (k) {
                    case 'ip':
                        t1 = this.ipToNumber(a.ip);
                        t2 = this.ipToNumber(b.ip);
                        return this.order.sort === 'asc' ? t1 - t2 : -t1 + t2;
                    case 'enable':
                        t1 = a.gg26_ == '1' ? 1 : 0;
                        t2 = b.gg26_ == '1' ? 1 : 0;
                        return this.order.sort === 'asc' ? t1 - t2 : -t1 + t2;
                        case 'time':
                            t1 = a.online === true ? 1 : 0;
                            t2 = b.online === true ? 1 : 0;
                            return  this.order.sort==='asc'?t1 - t2 : -t1 + t2;
                    case 'gg9_':
                    case 'gg12_':
                    case 'gg13_':
                    case 'gg14_':
                        return this.order.sort === 'asc' ? a[k] - b[k] : -a[k] + b[k];
                    default:
                        t1 = a['gg18_'].toString().replace('history', '').trim();
                        t2 = b['gg18_'].toString().replace('history', '').trim();
                        t1 = isNaN(parseInt(t1)) ? 0 : parseInt(t1);
                        t2 = isNaN(parseInt(t2)) ? 0 : parseInt(t2);
                        return this.order.sort === 'asc' ? (t1 - t2) : (-t1 + t2);
                }
            });
            base.list = arr;
            return base;
        },
    },
    methods: {
        serverUpdate(data) {
            switch (data.type) {
                case 'delete':
                    this.$delete(this.serverItem, data.ip);
                    break;
                default:
                    this.$set(this.serverItem, data.ip, data.data);
            }
            // this.$emit(this.serverItem, ip, serverData.data);
        },
        sort(name) {
            this.order.order = name;
            switch (this.order.sort) {
                case 'desc':
                    this.order.sort = 'asc';
                    break;
                case 'asc':
                    this.order.sort = 'desc';
                    break;
            }
        },
        ipToNumber(ip) {
            let numbers = ip.split(".");
            return parseInt(numbers[0]) * 256 * 256 * 256 +
                parseInt(numbers[1]) * 256 * 256 +
                parseInt(numbers[2]) * 256 +
                parseInt(numbers[3]);
        },
        allConfig(type) {
            if (this.setAll.running) return;
            let form;
            form = F.get('historyServer', 'editAll', {__type__: type});
            form.actionName = 'editAll';
            switch (type) {
                case 'gg22_':
                    form.title = '每秒允许请求数据';
                    break;
                case 'gg43_':
                    form.title = '手机访问(为空表示不允许手机访问)';
                    break;
                case 'gg44_':
                    form.title = 'POST1放行比例';
                    break;
                default:
                    this.$message.warning('未知类型!');
                    return;
            }
            this.form = form;
            this.setAll = {
                running: true,
                index: 0,
                type,
                list: JSON.parse(JSON.stringify(this.serverInfo.list)),
            }
        },
        runAll() {
            let data = this.setAll.data;
            switch (this.setAll.type) {
                case 'gg22_':
                    data.gg22_ = parseInt(data.gg22_);
                    if (isNaN(data.gg22_) || data.gg22_ > 200 || data.gg22_ < 0) {
                        this.$message.warning('参数错误!');
                        return;
                    }
                    break;
                case 'gg43_':
                    data.gg43_ = data.gg43_ ? '1' : '';
                    break;
                case 'gg44_':
                    data.gg44_ = parseInt(data.gg44_);
                    if (isNaN(data.gg44_) || data.gg44_ > 100 || data.gg44_ < 0) {
                        this.$message.warning('参数错误!');
                        return;
                    }
                    break;
                default:
                    this.setAll = {};
                    return;
            }
            data.ip = this.setAll.list[this.setAll.index] && this.setAll.list[this.setAll.index]['ip'];
            data.gg57_=this.setAll.list[this.setAll.index] && this.setAll.list[this.setAll.index]['gg57_'];
            if (!data.ip) {
                this.setAll = {};
                this.$message.success('全部已经完成');
                return;
            }
            // console.log(data);
            this.$axiosServer.post(this.baseUrl, data).then((serverData) => {
                let title = this.setAll.list[this.setAll.index]['gg18_'];
                this.$message.success(title + '--设置成功,完成 ' + (this.setAll.index + 1));
                this.setAll.index++;
                serverData.data && this.$set(this.serverItem, data.ip, serverData.data);
                setTimeout(() => {
                    this.runAll();
                }, 1000);
            });
        },
        submit: function (data) {
            if (data.type === 'cancel') {
                this.form.show = false;
                return;
            }
            switch (this.form.actionName) {
                case 'editConfig':
                    data.data.action = 'config';
                    this.$axiosServer.post(this.baseUrl, data.data).then((serverData) => {
                        this.$message.success('保存成功!');
                        serverData.data && this.$set(this.serverItem, data.data.ip, serverData.data);
                    });
                    break;
                case 'editAll':
                    data.data.action = 'config';
                    this.setAll.data = {
                        action: 'config',
                        [this.setAll.type]: data.data.__all__,
                    }
                    this.runAll();
                    break;
                case 'editSubscribe':
                    data.data.action = 'edit-subscribe';
                    this.$axiosServer.post(this.baseUrl, data.data).then(() => {
                        this.$message.success('保存成功!');
                    });
                    break;
                case 'editProxy':
                    data.data.action = 'edit-proxy';
                    if (!data.data.gg31_) data.data.gg31_ = '';
                    this.$axiosServer.post(this.baseUrl, data.data).then((serverData) => {
                        this.$message.success('保存成功!');
                        serverData.data && this.$set(this.serverItem, data.data.ip, serverData.data);
                    });
                    break;
                case 'selectProxy':
                    data.data.action = 'select-proxy';
                    if (!data.data.gg31_) data.data.gg31_ = '';
                    this.$axiosServer.post(this.baseUrl, data.data).then((serverData) => {
                        this.$message.success('保存成功!');
                        serverData.data && this.$set(this.serverItem, data.data.ip, serverData.data);
                    });
                    break;
            }
            this.form.show = false;
        }
    }
}
</script>
<style scoped lang="less">
.card {
    padding: 20px 5px;
    border: none;
    color: #f1f1f1;
    margin-top: 1rem;
    transition: all ease-in-out 0.3s;
    box-shadow: 0 1px 1px rgba(97, 108, 121, 0.1), 0 7px 18px 0 rgba(97, 108, 121, 0.13);

    &:hover {
        box-shadow: 0 1px 1px rgba(97, 108, 121, 0.1), 0 7px 18px 0 rgba(21, 111, 170, 0.21);
    }

    &.bg_user_add {
        //background-color: #8892d6;
        background-color: #45bbe0;
        //background-image: linear-gradient(to top, #a1c4fd 0%, #c2e9fb 100%);
    }

    &.bg_user_sum {
        background-color: #45bbe0;
        background-image: linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%);
    }

    &.bg_wx_add {
        background-color: #f06292;
    }

    &.bg_wx_sum {
        background-color: #78c350;
        /*background-image: linear-gradient(to top, #9982d1 0%, #fbc2eb 100%);*/
    }

    &.bg_poi_app {
        /*background-color:#9982d1 ;*/
        background-image: linear-gradient(to top, #9982d1 0%, #fbc2eb 100%);
    }

    .card-icon {
        .svg-icon, i {
            font-size: 3rem;
        }

    }

    .sub-title {
        margin-bottom: 5px;
        color: #555;
        font-size: 0.8rem;
    }

    .count {
        font-size: 1.5rem;
    }
}

.chart {
    background-color: #fff;
}
</style>
