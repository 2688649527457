<template>
    <div>
        <el-dialog
            :title="title"
            :visible.sync="isShow"
            width="80%"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
        >
            <div class="log">
                <div class="text-right mb-3">
                    <el-button type="primary" size="medium" icon="el-icon-plus" @click="addOne">增加一行</el-button>
                </div>
                <el-form label-position="left" label-width="80px" :model="item" v-for="(item,index) in subscribe" :key="item.id" class="demo-form-inline">
                    <el-input v-model="item.id" type="hidden"></el-input>
                    <el-row :gutter="15">
                        <el-col :md="4">
                            <el-form-item label="购买人">
                                <el-input v-model="item.title" placeholder="购买人"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :md="5">
                            <el-form-item label="URL">
                                <el-input v-model="item.url" placeholder="订阅地址"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :md="5">
                            <el-form-item label="购买地址">
                                <el-input v-model="item.buy_url" placeholder="购买地址"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :md="4">
                            <el-form-item label="同时在线">
                                <el-input v-model="item.limit" placeholder="同时在线"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :md="4">
                            <div>{{item.left?item.left:''}}  使用:{{item.in_use}}</div>
                            <div>{{item.left_time}}</div>
                        </el-col>
                        <el-col :md="1">
                            <el-switch class="mt-2"
                                       v-model="item.resubscribe"
                                       active-color="#13ce66"
                                       inactive-color="#ff4949">
                            </el-switch>
                        </el-col>
                        <el-col :md="1">
                            <el-button class="mt-1" @click="removeOne(index)" type="danger" size="mini" icon="el-icon-delete" circle></el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="saveData">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "server-subscribe",
    data:function (){
        return {
            isShow:false
        }
    },
    props:{
        subscribe:{
            type:Array,
            require:true,
        },
        title:{
            type:String,
            default:'日志记录'
        },
        show:{
            type:Boolean,
            require: true,
        }
    },
    watch:{
        show:function (newVal){
            this.isShow=newVal;
        }
    },
    methods:{
        addOne(){
            this.subscribe.push({id:new Date().getTime().toString(),title:'',url:'',online:10,resubscribe:true});
        },
        saveData(){
            this.isShow=false;
            // console.log(this.subscribe);
            let data={};
            this.subscribe.map(v=>{
                delete v.list;
                data[v.id]=v;
            });
            // http://127.0.0.1:9502/google/api/subscribe?bigemap=1
            this.$axiosServer.put('/google/api/subscribe?bigemap=1',{subscribe:data}).then(()=>{
                // console.log(data);
                this.$message.success('保存成功!');
            });
        },
        removeOne(index){
            this.subscribe.splice(index,1);
        }
    }
}
</script>

<style scoped lang="less">

</style>