class ServerWebsocket{
    /**
     * @type {WebSocket}
     */
    socket;
    url;
    config;
    messageEvent;
    isConnect=false;
    userClose=false;
    constructor(url,config) {
        this.url=url;
        this.config=config;
        this.init()
    }
    init(){
        this.isConnect=true;
        this.socket=new WebSocket(this.url);
        this.socket.addEventListener('close',this._error.bind(this));
        if (this.config['message']) this.socket.addEventListener('message',this.config.message);
        if (this.config['open']) this.socket.addEventListener('open',this.config.open);
    }
    on(type,fn){
        this.socket.addEventListener(type,fn);
    }
    close(){
        this.userClose=true;
        this.isConnect=false;
        this.socket&&this.socket.close();
        this.socket=null;
    }
    _error(){
        if (this.userClose) return false;
        this.isConnect=false;
        this.socket&&this.socket.close();
        this.socket=null;
        this.reconnect();
    }
    reconnect(){
        if (this.isConnect){
            return;
        }
        this.init();
    }
}
export default ServerWebsocket;