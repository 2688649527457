<template>
    <span>{{sum}}</span>
</template>

<script>
export default {
    props:{
        data:{
            require:true,
            type:Number,
        }
    },
    name: "format-b",
    computed:{
        sum:function (){
            let tmp=this.data;
            let index=0;
            for (let i=0;i<=10;i++){
                index=i;
                if (tmp<1024){
                    break;
                }
                tmp=(tmp/1024).toFixed(2);
            }
            let delta=['B','KB','M','GB'];
            return tmp+delta[index];
        }
    }
}
</script>

<style scoped>

</style>