<template>
    <div>
        <el-dialog
            custom-class="my-dia"
            :title="title"
            :visible.sync="isShow"
            width="60%"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            >
            <div class="log">
                <div class="log-area">
                    <div v-for="(item,index) in log" :key="index" class="log-item">
                        {{item}}
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="isShow = false">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "server-log",
    data:function (){
        return {
            isShow:false
        }
    },
    props:{
        log:{
            type:Array,
            require:true,
        },
        title:{
          type:String,
          default:'日志记录'
        },
        show:{
            type:Boolean,
            require: true,
        }
    },
    watch:{
        show:function (newVal){
            this.isShow=newVal;
        }
    }
}
</script>

<style scoped lang="less">
    .log{
        background-color: rgb(0,0,0);
        height: 60vh;
        .log-area{
            height: 100%;
            overflow: auto;
        }
        .log-item{
            color: #fff;
            padding: 5px;
        }
    }
</style>